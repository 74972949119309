import React from 'react';
import SEO from '../components/seo';
import ErrorSec from '../containers/Error';
import { ResetCSS } from 'common/src/assets/css/style';
import Layout from '../components/layout';

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <ResetCSS />
    <Layout>
      <ErrorSec></ErrorSec>
    </Layout>
  </>
);

export default NotFoundPage;
